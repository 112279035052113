/* eslint-disable vue/require-name-property */

import Vue from "vue";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

const App = () => import("./App.vue");
const CONFIG_FILE_NAME = "web-rtc-workshop-config.json";

Vue.config.productionTip = false;

fetch(process.env.BASE_URL + CONFIG_FILE_NAME).then(response => {
  response.json().then(config => {
    Vue.prototype.$config = config;
    new Vue({
      router,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  });
});
