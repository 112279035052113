import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/bwa",
    name: "bwa",
    component: () => import("../views/Bwa.vue")
  },
  {
    path: "/jhe/:tab",
    name: "jhe",
    component: () => import("../views/Jhe.vue"),
    props: true
  },
  {
    path: "/sku",
    name: "sku",
    component: () => import("../views/Sku.vue")
  },
  {
    path: "/dro",
    name: "dro",
    component: () => import("../views/Dro.vue")
  },
  {
    path: "/dsc",
    name: "dsc",
    component: () => import("../views/Dsc.vue")
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("../views/Sample.vue")
  },
  {
    path: "/scrumpoker",
    name: "scrumpoker",
    component: () => import("../views/ScrumPoker.vue")
  },

  // path: '/' muss als letztes definiert werden
  {
    path: "/",
    name: "default",
    component: () => import("../views/ScrumPoker.vue") //"../views/Default.vue"
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
