export default {
  light: {
    primary: "#0B379C",
    secondary: "#CED7EB",
    accent: "#0B379C",
    error: "#F44336",
    warning: "#FFAB00",
    info: "#FFEB3B",
    success: "#00C853",
    font: "#212121",
    background: "#FFF",
    selection: "#E1E6F3",
    hover: "#F6F6F6",
    disabled: "#9e9e9e"
  },
  dark: {
    primary: "#00D0FF",
    secondary: "#444",
    accent: "#00D0FF",
    error: "#F44336",
    warning: "#FFAB00",
    info: "#FFEB3B",
    success: "#00C853",
    font: "#FFF",
    background: "#000",
    selection: "#174954",
    hover: "#303030",
    disabled: "#8f8f8f"
  }
};
